import axios from 'axios'
import Immutable from 'immutable'

import { DashboardStorage } from '../../../../utils'

export const loadPublicClip = async ({ slug }) => {
  const clipRes = await axios.get(
    `${process.env.REACT_APP_HEROKUDATA_BACKEND}/dataclips/${slug}`,
    {
      headers: {
        Accept: 'application/json',
        'X-Heroku-Data-Tab': DashboardStorage.getSessionItem('herokudataTabId')
      }
    }
  )
  const {
    result,
    latest_result_at,
    latest_result_checksum,
    latest_result_size,
    id,
    ...rest
  } = clipRes.data.clip
  return Immutable.fromJS({
    slug,
    id,
    detached: false,
    editable: false,
    ...rest,
    versions: [
      {
        latest_result_at,
        latest_result_checksum,
        latest_result_size,
        result: {
          ...result
        }
      }
    ]
  })
}
