import axios from 'axios'

const getBearerToken = async ({ code }) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_FRONT_END_AUTH_URL}/auth/heroku-dhc/token`,
      {
        code,
        grant_type: 'authorization_code'
      }
    )
    return res.data
  } catch (e) {
    throw new Error('Failed to get token from Front End Auth')
  }
}

export default getBearerToken
